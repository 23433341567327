<template>
    <div class="turn-page clearfix" style="padding: 8px;">
        <div class="pull-right pages">
            <span v-if="Math.ceil(turnPage.totalCount/turnPage.pageSize)>1" @click="pageFn('prev')" class="el-icon-arrow-left"></span>
            <span @click="pageFn(1)" v-if="turnPage.pageNo-3>=1">1</span>
            <em v-if="turnPage.pageNo-3>1">……</em>
            <template v-for="(one,$index) in Math.ceil(turnPage.totalCount/turnPage.pageSize)">
                <span :key="one" v-if="filterPage($index)" :class="{'active':($index+1)==turnPage.pageNo}" @click="pageFn($index+1)">{{$index+1}}</span>
            </template>
            <em v-if="turnPage.pageNo+3<Math.ceil(turnPage.totalCount/turnPage.pageSize)&&turnPage.pageNo+3+1!=Math.ceil(turnPage.totalCount/turnPage.pageSize)">……</em>
            <span @click="pageFn(Math.ceil(turnPage.totalCount/turnPage.pageSize))" v-if="turnPage.pageNo+3<Math.ceil(turnPage.totalCount/turnPage.pageSize)">{{Math.ceil(turnPage.totalCount/turnPage.pageSize)}}</span>
            <span v-if="Math.ceil(turnPage.totalCount/turnPage.pageSize)>1" @click="pageFn('next')" class="el-icon-arrow-right"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        turnPage: Object
    },
    data: function () {
        return {
            counter: this.turnPage
        }
    },
    methods: {
        
        //翻页
        pageFn: function (index) {
            let pageNo = this.turnPage.pageNo;
            let pageCount = Math.ceil(this.turnPage.totalCount / this.turnPage.pageSize);
            switch (index) {
                case 'prev':
                    pageNo--;
                    break;
                case 'next':
                    pageNo++;
                    break;
                default:
                    pageNo = index;
            }
            if (pageNo < 1) {
                pageNo = 1;
            } else if (pageNo > pageCount) {
                pageNo = pageCount;
            }
            this.turnPage.pageNo = pageNo;
            this.$emit('childFn', this.turnPage);
        },

        //过滤翻页
        filterPage (index) {
            return (this.turnPage.pageNo - 3 <= index && index < this.turnPage.pageNo + 3)
        }

    }
}
</script>

<style scoped>
.turn-page {
    display: flex;
    justify-content: center;
    padding: 0 0 60px 0;
    font-weight: bold;
}
.pages {
    display: flex;
}
.turn-page .pages > span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    border: 1px solid #f3f3f3;
    cursor: pointer;
    width: 40px;
    height: 35px;
    border-radius: 6px;
}

.turn-page .pages > span.left,
.turn-page .pages > span.right {
    color: #f3f3f3;
}
.turn-page .pages > span:hover,
.turn-page .pages > span.active {
    background: #20ceab;
    color: white;
}

.turn-page .pages > span.glyphicon {
    top: 0;
}
</style>