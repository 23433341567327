<template>
    <span>
        <i class="badge-icon" v-for="(item, key) of filterBadge" :key="key">
            <template v-if="+item === 1">
                <i v-for="(itemChild, keyChild) of badgeMap[key].icon" :key="keyChild">
                    <el-popover v-if="+item === +keyChild" placement="top-start" width="150" trigger="hover" :content="badgeMap[key].content">
                        <img slot="reference" :src="require(`@/assets/images/icon/${itemChild}.png`)" />
                    </el-popover>
                </i>
            </template>
        </i>
    </span>
</template>

<script>
export default {
    props: {
        badge: {
            type: Object,
            default: () => {
                return {
                    // badge1: "",
                    // badge2: "",
                    // badge3: "",
                    // badge4: "",
                    // badge5: "",
                    // badge6: "",
                    // badge7: "",
                }
            }
        }
    },

    data: () => ({
        badgeMap: {
            badge1: {
                content: '实名认证师傅徽章',
                icon: {
                    '1': 'smrzhz'
                }
            },
            badge2: {
                content: '师傅保证金徽章',
                icon: {
                    '1': 'sfbzjhz1',
                    '2': 'sfbzjhz2',
                    '3': 'sfbzjhz3',
                }
            },
            badge3: {
                content: '平台优选师傅徽章',
                icon: {
                    '1': 'ptyxsfhz'
                }
            },
            badge4: {
                content: '平台知识认证师傅徽章',
                icon: {
                    '1': 'ptzsrzsfhz1',
                    '2': 'ptzsrzsfhz2',
                    '3': 'ptzsrzsfhz3',
                }
            },
            badge5: {
                content: '平台规则熟读师傅徽章',
                icon: {
                    '1': 'ptgzsdsfhz'
                }
            },
            badge6: {
                content: '平台服务承诺师傅徽章',
                icon: {
                    '1': 'ptfwcnsfhz'
                }
            },
            badge7: {
                content: '平台守时师傅徽章',
                icon: {
                    '1': 'ptsssfhz'
                }
            },
        }
    }),
    computed: {
        filterBadge () {
            const { badge1, badge2, badge3, badge4, badge5, badge6, badge7 } = this.badge
            return { badge1, badge2, badge3, badge4, badge5, badge6, badge7 }
        }
    },
    watch: {
        badge: {
            handler (val) {
                console.log(val)
            },
            deep: true,
            immediate: true
        }
    },
    mounted () { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.badge-icon {
    display: inline-block;
    // margin-right: 1px;
    img {
        margin-right: 4px;
    }
}
</style>