import Vue from 'vue'
import $constant from '../constant.js'
// console.log($constant)

import {
	checkBaiduPage,
	initBaiduBridge,
	removeBaiduBridge
} from './baidu'

const func = {

    preCdnList(pics) {
        // console.log(pics)
        const images = pics.map(v => {
            if (typeof v !== 'string') return v
            console.log(v)
            if (v.indexOf('http') > -1) {
                return v
            } else {
                return $constant.cdn + v
            }
        })
        console.log(images)
        return images
    },

    preCdn(pic) {
        // console.log(pic)
        if (!pic) return
        // console.log(pic)
        if (pic.indexOf('http') > -1) {
            // console.log(pic)
            return pic
        } else {
            return $constant.cdn + pic
        }
    },

    // validMobile (value) {
    //     return /^1[3-9]\d{9}$/.test(value)
    // },

    validMobile (value) {
        const reg1 = /^1[3-9]\d{9}$/
        const reg2 = /^1[3-9]\d{9}-\d{4}$/
        return reg1.test(value) || reg2.test(value)
    },

    // orderStatusCN (status, offerType) {
    //     if (offerType == 2 && status== '100') {
    //         return '待抢单'
    //     }
    //     // console.log(status)
    //     const map = new Map([
    //         ['100', '待报价'],
    //         ['200', '待雇佣'],
    //         ['300', '待付款'],
    //         ['400', '待预约'],
    //         ['500', '待取货'],
    //         ['600', '待上门'],
    //         ['700', '待验货'],
    //         ['800', '待完工'],
    //         ['900', '待验收'],
    //         ['1000', '待评价'],
    //         ['1001', '待评价'],
    //         ['1002', '已评价'],
    //         ['1100', '已完结'],
    //         ['1200', '交易关闭'],
    //         ['1201', '交易关闭'],
    //         ['1300', '师傅维权'],
    //         ['1301', '维权完成'],
    //         ['1400', '商家投诉'],
    //         ['1401', '投诉完成']
    //     ])
    //     return map.get('' + status)
    // },

    orderStatusCN (status, offerType, payStatus) {
        if (offerType == 2 && status== '100') {
            if (payStatus == 1) {
                return '待抢单'
            } else {
                return '待支付'
            }
            
        }
        // console.log(status)
        const map = new Map([
            ['100', '待报价'],
            ['200', '待雇佣'],
            ['300', '待付款'],
            ['400', '待预约'],
            ['500', '待取货'],
            ['600', '待上门'],
            ['700', '待验货'],
            ['800', '待完工'],
            ['900', '待验收'],
            ['1000', '待评价'],
            ['1001', '待评价'],
            ['1002', '已评价'],
            ['1100', '已完结'],
            ['1200', '交易关闭'],
            ['1201', '交易关闭'],
            ['1300', '师傅维权'],
            ['1301', '维权完成'],
            ['1400', '商家投诉'],
            ['1401', '投诉完成']
        ])
        return map.get('' + status)
    },

    fixedNum (num) {
        if (typeof num === 'number') return num.toFixed(2)
        if (typeof num === 'string') return (+num).toFixed(2)
        return undefined
    },

    flatImg (img) {
        if (!(img && img.length)) return;
        // let imgs = [];
        // for (let i = 0; i < img.length; i++) {
        //     imgs.push(img[i].url)
        // }
        return img.map(v => v.url)
    },

    arrToObj (arr, attr) {
        const obj = arr.reduce((acc, cur) => {
            acc[cur[attr]] = cur
            return acc
        }, {})
        return obj
    },
	
	checkBaiduPage,
    
	initBaiduBridge,
	
	removeBaiduBridge,

    
    /**
     * Parse the time to string
     * @param {(Object|string|number)} time
     * @param {string} cFormat
     * @returns {string | null}
     */
     parseTime (time, cFormat) {
        // 当没有登录时间时默认返回'0'
        if (time === '0' || time === 0) {
            return '-'
        }
        if (arguments.length === 0 || !time) {
            return null
        }
        const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
        let date
        if (typeof time === 'object') {
            date = time
        } else {
            if ((typeof time === 'string')) {
                if ((/^[0-9]+$/.test(time))) {
                    // support "1548221490638"
                    time = parseInt(time)
                } else {
                    // support safari
                    // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                    time = time.replace(new RegExp(/-/gm), '/')
                }
            }

            if ((typeof time === 'number') && (time.toString().length === 10)) {
                time = time * 1000
            }
            date = new Date(time)
        }
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
        }
        const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
            const value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
            if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
            return value.toString().padStart(2, '0')
        })
        return time_str
    }
}

Vue.prototype.$func = func

// Vue.use(popup)
// export default fetch